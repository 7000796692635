import "./assets/css/App.css";
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes as Switch } from "react-router-dom";

import Provider from "./helpers/hooks/useGlobalContext";
const Home = React.lazy(() => import('./pages/Home'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Provider>
        <Router>
          <Switch>
            <Route exact path="/" element={<Home />} />
            {/* <Route path="*" component={NotFound} /> */}
          </Switch>
        </Router>
      </Provider>
    </Suspense>
  );
}

export default App;
